const ServiceSection = ({
  className,
  image,
  altText,
  heading,
  hColor,
  content,
  pColor,
  imagePosition,
}) => {
  return (
    <section
      className={`service-section  ${className} ${
        imagePosition === "right" ? "image-right" : ""
      }`}
    >
      <img src={image} alt={altText} />
      <div>
        <p style={{ color: hColor ? hColor : "" }} className="heading">
          {heading}
        </p>
        <p style={{ color: pColor ? pColor : "" }}>{content}</p>
      </div>
    </section>
  );
};

export default ServiceSection;
