import React, { forwardRef } from 'react';
import constructionImage from '../images/construction.jpg'; // replace with your image
import itImage from '../images/it.jpg'; // replace with your image
import marketingImage from '../images/marketing.jpg'; // replace with your image
import ServiceSection from '../components/ServiceSection';
import Divider from './Divider';

export const Services= forwardRef((props, ref) => {
	return (
		<div className='services' ref={ref}>
			<ServiceSection
			    className="image-below"
				image={constructionImage}
				altText='Construction'
				content='V oblasti stavebných prác sme odborníkmi so skúsenosťami v rôznych typoch projektov. Sme schopní realizovať malé aj veľké stavebné projekty od základov až po dokončenie. Naši kvalifikovaní stavební inžinieri a pracovníci sa starajú o každý detail, aby sme dosiahli vynikajúce výsledky a spokojnosť našich klientov.'
                heading='Stavebné práce'
                hColor='#f9ad00'
				imagePosition='left'
                pColor='white'
			/>
			<Divider gap={150} />
			<ServiceSection
				className="image-below"
				image={itImage}
				altText='IT Services'
				content='V oblasti počítačových služieb sme špecialisti na tvorbu webových
                stránok a mobilných aplikácií. S naším skúseným tímom dizajnérov,
                vývojárov a programátorov sme schopní vytvárať moderné, funkčné a
                esteticky príjemné webové stránky a mobilné aplikácie. Bez ohľadu na
                to, či potrebujete jednoduchú webovú stránku pre vaše podnikanie alebo
                komplexnú mobilnú aplikáciu, sme pripravení vám pomôcť realizovať vašu
                víziu.'
                heading='IT služby'
                hColor='#f9ad00'
				imagePosition='right'
                pColor="white"
			/>
			<Divider gap={150} />
			<ServiceSection
				className="image-below"
				image={marketingImage}
				altText='Marketing Services'
				content='Okrem toho sa zaoberáme aj sprostredkovaním obchodu služieb a tovarov.
                Naša sieť kontaktov a partnerov nám umožňuje sprostredkovať širokú
                škálu služieb a tovarov, čo znamená, že môžeme pomôcť našim klientom
                nájsť presne to, čo potrebujú. Sme tu, aby sme vám uľahčili proces
                nájdenia a nakupovania potrebných služieb a tovarov s dôrazom na
                kvalitu, spoľahlivosť a konkurencieschopné ceny.'
                heading='Sprostredkovateľské služby'
                hColor='#f9ad00'
				imagePosition='left'
                pColor='white'
			/>
            <Divider gap={150} />
		</div>
	);
});
