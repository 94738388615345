import React from 'react'

export const Home = () => {
  return (
    <div className='section'>
      <div className="circle">
        <div className='inner-circle'>
          <div className="home__typography">
              <p className='home__heading'>Vitajte v našej spoločnosti!</p>
              <p className='section__lead'>
              Sme multifunkčná firma, ktorá sa špecializuje na stavebné práce, počítačové služby a sprostredkovanie obchodu služieb a tovarov. Sme hrdí na našu schopnosť poskytovať vysoko kvalitné služby a efektívne riešenia pre našich klientov.</p>
          </div>
        </div>
        </div>
    </div>
  )
}
