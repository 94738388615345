import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Home } from './components/Home';
import { Services } from './components/Services';
import Divider from './components/Divider';
import Form from './components/Form';
import './App.css';

function App() {
	const servicesRef = useRef(null);
	const contactRef = useRef(null);

	return (
		<Router>
			<div className='App'>
				<Routes>
					<Route
						path='/*'
						element={
							<>
								<Navbar servicesRef={servicesRef} contactRef={contactRef} />
								<Home />
								<Services ref={servicesRef} />
								<Form ref={contactRef} />
								<Divider />
							</>
						}
					/>
				</Routes>
			</div>
		</Router>
	);
}

export default App;
