import React, { useState, forwardRef } from "react";
import emailjs from "emailjs-com";
import Input from "./Input";
import Button from "./Button";
import Textarea from "./Textarea";
import { toast } from "react-toastify";

const Form = forwardRef((props, ref) => {
  const [formState, setFormState] = useState({
    email: "",
    description: "",
  });

  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Process form data here...
    emailjs
      .send(
        "service_dxynzpb", // replace with your service ID
        "template_8oasvtp", // replace with your template ID
        formState, // the form data to be sent, in your case it's formState
        "7RXxBOWM_r5i35Z3x" // replace with your user ID
      )
      .then(
        (result) => {
          toast.success("Email successfully sent!");
          setFormState({
            email: '',
            description: ''
        });
        },
        (error) => {
          toast.error("An error occurred."); // Error toast here
        }
      );
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form" ref={ref}>
      <h1>Kontaktujte nás</h1>
      <Input
        type="email"
        name="email"
        value={formState.email}
        placeholder="Email"
        onChange={handleChange}
      />
      <Textarea
        name="description"
        value={formState.description}
        placeholder="Popis..."
        onChange={handleChange}
      />
      <Button text="Odoslať" />
    </form>
  );
});

export default Form;
