import React from 'react';

const Textarea = ({ name, placeholder, onChange,value }) => {
    return (
        <textarea 
            name={name} 
            placeholder={placeholder} 
            onChange={onChange}
            value={value}
            className="textarea-field"
        />
    );
};

export default Textarea;
