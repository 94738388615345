import React from 'react';

const Input = ({ type, name, placeholder, onChange, value }) => {
    return (
        <input 
            type={type} 
            name={name} 
            placeholder={placeholder} 
            value={value}
            onChange={onChange}
            className="input-field"
        />
    );
};

export default Input;
