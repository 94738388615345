import { Link } from 'react-router-dom'; 
import logo from '../images/bw.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faCogs, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import scrollIntoView from 'scroll-into-view-if-needed';

export const Navbar = ({ servicesRef, contactRef }) => {
	const handleClick = (e) => {
		e.preventDefault();
		scrollIntoView(servicesRef.current, {
		  scrollMode: 'if-needed',
		  behavior: 'smooth',
		  block: 'start',
		});
	};

	const handleContactClick = (e) => {
		e.preventDefault()
		scrollIntoView(contactRef.current, {
			scrollMode: 'if-needed',
			behavior: 'smooth',
			block: 'start',
		  });
	}
	return (
		<nav>
			<div className='logo-container'>
				<Link to='/'>
					<img src={logo} alt='logo' className='logo' />
				</Link>
			</div>
			<ul className='nav-links'>
				<li>
					<a href='#services' onClick={handleClick}>
					<FontAwesomeIcon icon={faCogs} style={{color: "#f9ad00"}} className='icon'/> Služby</a>
				</li>
				<li>
					<a href='#kontakt' onClick={handleContactClick}>
					<FontAwesomeIcon icon={faEnvelope} style={{color: "#f9ad00"}} className='icon'/> Kontakt</a>
				</li>
				<li>
					<a href='tel:+421-915-522-159'>
					<FontAwesomeIcon icon={faPhone} style={{color: "#f9ad00"}} className='icon'/>
					+421 915 522 159</a>
				</li>
			</ul>
		</nav>
	);
};

